import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";

import { REACT_APP_API_URL } from "../config";

import AuthComponent from "../components/AuthComponent";
import { useSiteData } from "../SiteDataContext";

function Login() {
  const original_usernameTitle = "Логин";
  const [usernameTitle, setUsernameTitle] = useState(original_usernameTitle);
  const [isFormError, setIsFormError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { siteData, setSiteData } = useSiteData();

  const handleFormIsChanged = () => {
    setIsFormError(false);
    setUsernameTitle(original_usernameTitle);
  };

  const loginUser = async ({ username, password }) => {
    const userData = {
      username: username,
      password: password,
    };

    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/api/login/`,
        userData
      );

      if (response.data.status === "success" && response.data.token) {
        localStorage.setItem("auth_token", response.data.token);
        localStorage.setItem("auth_username", username);
        
        setSiteData({ ...siteData, username: username });

        navigate("/");
      } else {
        throw new Error(
          `The response was not ok: ${response} ${response.statusText}`
        );
      }
    } catch (error) {
      let errorMessage = "Ошибка при регистрации";

      if (error.response) {
        console.error("Error response:", error.response.data);
        if (error.response.data.status === "error") {
          errorMessage = error.response.data.message;
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error:", error.message);
      }

      setUsernameTitle(errorMessage);
      setIsFormError(true);
    }
  };

  return (
    <div className="mh-152 bg-white-blue flex justify-center items-center h-screen">
      <AuthComponent
        usernameTitle={usernameTitle}
        isFormError={isFormError}
        handleFormIsChanged={handleFormIsChanged}
        additionalContent={
          <div className="font-medium text-sm lh-1-2 text-center px-3 mt-8">
            Не помнишь данные для входа?{" "}
            <a href="/new-user" className="underline color-green">
              Заведи новый аккаунт
            </a>
          </div>
        }
        authMessage={location?.state?.message}
        submit={({ username, password }) => {
          const userData = {
            username: username,
            password: password,
          };
          loginUser(userData);
        }}
      />
    </div>
  );
}

export default Login;
